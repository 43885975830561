#scroller {
  width: 100%;
  // height: 100%; mobile
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  transition: all 1s;
  position: relative;
  // border: 1px solid black;
}

.scroller-overflow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // overflow: hidden;
  // tests
  // overflow: none;
  overflow-y: hidden;
  overflow-x: hidden;
}
.scroller-content {
  width: 100%;
  // height: 100%; // do not touch!
  transition: all 1s;
  overflow-x: hidden;
  // border: 1px solid green;
  // overflow-x: hidden;
  // overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.item {
  width: 100%;
  transition: all 1s;
  @include mobile {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}

.cup {
  transition: all 1s;
}

.final {
  #scroller {
    transition: none;
  }
  .scroller-overflow {
    transition: none;
  }
  .scroller-content {
    transition: none;
  }
  .item {
    transition: none;
  }
  .cup {
    transition: none;
  }
}
