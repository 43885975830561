@import 'mixins';
.favorit-brands {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .next-button {
    margin-top: 3.8vw;
    @include FourK {
      margin-top: 80px;
    }
  }
  .brands-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .row {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
  }
  .mini-title {
    margin-top: 6vw;
    margin-bottom: 23px;
  }
  .grey-text-mini {
    margin-bottom: 3vw;
  }
  padding: 5vw 6vw 5.8vw 6vw;
  @include desktop {
    padding: 5vw 7vw;
  }
  @include tablet {
    padding: 5vw 1.5vw;
    .brands-container {
      flex-direction: row;
      .row {
        flex-direction: column;
        flex-wrap: wrap;
      }
    }
  }
  @include mobile {
    padding: 20px 5px;
    margin-top: 15px;
    .mini-title {
      margin: 15px;
    }
    .grey-text-mini {
      margin-top: 0;
      line-height: 26px;
    }
    .brands-container {
      flex-direction: row;
      .row {
        flex-direction: column;
      }
    }
  }
  @include mobile-l {
    padding: 4vw 0;
    .mini-title {
      margin: 10px;
    }
    .brands-container {
      flex-direction: column;
      .row {
        flex-direction: column;
        .brands-card {
          margin: 10px;
        }
      }
    }
  }
}
