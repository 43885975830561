@import '_variables';

.next-button,
.next-button-disabled {
  box-sizing: border-box;
  display: block;
  min-width: 180px;
  padding: 18px 40px;
  text-align: center;
  position: relative;
  transition: all 0.35s;
  user-select: none;
  outline: 0;
  cursor: pointer;
  svg {
    transform: rotate(180deg);
    path {
      fill: $COLOR_TEXT_BLACK;
    }
  }
}

.next-button-text {
  font-style: normal;
  font-family: Futura;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  color: $COLOR_TEXT_BLACK;
  position: relative;
  z-index: 2;
}

.next-button:before,
.next-button-disabled:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 32%;
  height: 100%;
  background: $COLOR_SOFT_PINK;
  transition: all 0.35s;
}

.next-button:after,
.next-button-disabled:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: $COLOR_PINK;
  transition: all 0.1s;
}

.next-button-disabled:before {
  background: $COLOR_GREY_BORDER;
}

.next-button:active {
  .next-button-text {
    color: #fff;
  }
  svg {
    path {
      fill: $COLOR_WHITE;
    }
  }
}

.next-button:hover {
  svg {
    transform: rotate(180deg) translateX(-4px) scaleX(1.2);
  }
}

.next-button:hover:before {
  width: 100%;
}
.next-button:active:after {
  width: 100%;
}
