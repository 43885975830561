@import '_variables';
@import '_mixins';
.inp {
  input {
    font-family: Futura;
    background: none;
    color: $COLOR_TEXT_BLACK;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 110%;
    text-align: center;
    padding: 10px 10px 10px 5px;
    @include mobile {
      padding: 10px 10px 10px 10px;
    }
    display: block;
    width: 140px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $COLOR_GREY_BORDER;
    text-overflow: ellipsis;
  }
  input::placeholder {
    text-overflow: ellipsis;
  }
  input::-ms-clear {
    display: none;
  }
  input:focus {
    outline: none;
  }
  input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #737079;
    opacity: 1; /* Firefox */
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #737079;
  }

  input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #737079;
  }
  input:focus ~ .border:before {
    width: 160px;
  }
  .border {
    position: relative;
    display: block;
    width: 160px;
    &:before {
      content: '';
      height: 2px;
      width: 0;
      bottom: 0px;
      position: absolute;
      background: $COLOR_PINK;
      transition: 0.3s ease all;
      left: 0%;
    }
  }
  // @include mobile-l {
  //   input {
  //     width: 110px;
  //   }
  //   input:focus ~ .border:before {
  //     width: 130px;
  //   }
  //   .border {
  //     width: 130px;
  //   }
  // }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
