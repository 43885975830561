@import '_variables';
@import '_mixins';

.select-main {
  margin-top: 24px;
  position: relative;
  .select-error {
    position: absolute;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    color: $RED;
  }
}

.select-bordered {
  position: relative;
  width: 250px;
  outline: none;
  padding: 20px;
  border: 1px solid $COLOR_GREY_BORDER;
  border-radius: 1px;
  box-sizing: border-box;
  margin-bottom: 5px;
  margin-top: 13px;
  .select-inp {
    background: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: #acabae;
      font-family: Value;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
    }
    .selected {
      color: $COLOR_TEXT_BLACK;
    }
  }

  .focused {
    color: $COLOR_TEXT_BLACK;
    path {
      fill: $COLOR_TEXT_BLACK;
    }
  }

  svg {
    path {
      fill: $COLOR_TEXT_LIGHT;
    }
  }
}
.focus {
  border: 1px solid $COLOR_SOFT_PINK;
}
.error {
  border: 1px solid $RED;
}
.select-mini-option {
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: $COLOR_TEXT_BLACK;
  padding-left: 20px;
}

.checked-mini {
  background-color: $COLOR_INLINE_PINK;
}

.select-container {
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  height: 0px;
  box-sizing: border-box;
  border-radius: 1px;
  overflow-y: auto;
  z-index: 99999;
  transition: height 0.4s;
}
.focused-cont {
  border: 1px solid $COLOR_CHECK_BOX_BORDER;
  padding: 20px 0 14px 0;
  height: 315px;

  @include mobile {
    height: 200px;
  }
}
.select-label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: $COLOR_TEXT_LIGHT;
  &__required {
    color: $RED;
  }
  &__optional {
    color: $COLOR_GREY_BORDER;
  }
}

@include mobile-l {
  .select-mini {
    width: 110px;
    .select-inp .focused ~ .border:before {
      width: 110px;
    }
    .border {
      width: 110px;
    }
  }
}
