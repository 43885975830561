@import '_variables';
@import '_mixins';

.search-input {
  position: relative;
  .search-list {
    max-height: 680px;
    overflow-y: auto;
    position: absolute;
    background: $COLOR_WHITE;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
    border-radius: 1px;
    width: 100%;
    z-index: 999999;
    @include mobile {
      max-height: 340px;
    }
  }
  .search-item {
    padding: 20px;
    font-family: Value;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: $COLOR_TEXT_BLACK;
    z-index: 9999999;
    outline: none;
    cursor: pointer;
  }
  .search-item:hover {
    background: #b6a0b440;
  }
  &__container {
    display: flex;
    flex-direction: row;
    input {
      width: 30vw;
      background: none;
      color: $COLOR_TEXT_BLACK;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 110%;
      text-align: left;
      padding: 10px 10px 10px 5px;
      display: block;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $COLOR_GREY_BORDER;
      outline: none;
      @include mobile {
        width: 50vw;
      }
      @include mobile-l {
        width: 100%;
      }
    }
    input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #737079;
      opacity: 1; /* Firefox */
      @include mobile {
        font-size: 14px;
      }
      @include mobile-l {
        font-size: 12px;
      }
    }

    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #737079;
      @include mobile {
        font-size: 14px;
      }
      @include mobile-l {
        font-size: 12px;
      }
    }

    input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #737079;
      @include mobile {
        font-size: 14px;
      }
      @include mobile-l {
        font-size: 12px;
      }
    }
    input::-ms-clear {
      display: none;
    }
  }
  &__button {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $COLOR_SOFT_PINK;
    outline: none;
  }
  &__button:hover {
    background-color: darken($COLOR_SOFT_PINK, 5%);
  }
}

.disabled {
  .search-input__button {
    background: $COLOR_GREY_BORDER !important;
  }
}
