@import '_variables';
@import '_mixins';

.select-mini {
  position: relative;
  width: 130px;
  outline: none;
  margin: 0 5px;
  padding: 10px 10px 10px 5px;
  .select-inp {
    position: relative;
    background: none;
    color: $COLOR_TEXT_LIGHT;
    font-style: normal;
    font-weight: normal;
    font-family: Futura !important;
    font-size: 16px;
    line-height: 110%;
    text-align: center;
    display: block;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $COLOR_GREY_BORDER;
  }

  .focused {
    color: $COLOR_TEXT_BLACK;
  }

  .select-inp .focused ~ .border:before {
    width: 130px;
  }
  .border {
    position: relative;
    display: block;
    width: 130px;
    margin-top: 10px;
    &:before {
      content: '';
      height: 2px;
      width: 0;
      bottom: 0px;
      position: absolute;
      background: $COLOR_PINK;
      transition: 0.3s ease all;
      left: 0%;
    }
  }
  svg {
    position: absolute;
    right: 4px;
    top: 30%;
    path {
      fill: $COLOR_TEXT_LIGHT;
    }
  }
  .focused {
    path {
      fill: $COLOR_TEXT_BLACK;
    }
  }
  .select-container {
    position: absolute;
    top: 45px;
    width: 105%;
    box-sizing: border-box;
    border-radius: 1px;
    padding: 20px 0 14px 0;
    overflow-y: auto;
    height: 160px;
    @include mobile {
      height: 240px;
    }
    display: none;
  }
  .focused-cont {
    display: block;
    border: 1px solid $COLOR_CHECK_BOX_BORDER;
    background-color: $COLOR_WHITE;
  }
}
.select-mini-option {
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: $COLOR_TEXT_BLACK;
  padding-left: 20px;
  z-index: 9999999;
}

.checked-mini {
  background-color: $COLOR_INLINE_PINK;
}
