@import '_variables';
@import '_mixins';

.question-template {
  // position: absolute;
  overflow: hidden;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  .question-header {
    width: 100%;
    height: 70px;
    background-color: $COLOR_WHITE;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 54px;
  }
  .question-content {
    position: relative;
    flex: 1;
    display: flex;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    @include mobile-l {
      align-items: flex-start;
    }
    @include mobile {
      align-items: flex-start;
    }
  }
}
