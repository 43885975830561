@mixin mobile-l {
  @media (max-width: 426px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin mobile-tablet {
  @include mobile {
    @content;
  }
  @include tablet {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1025px) and (max-width: 1200px) {
    @content;
  }
}
@mixin desktop-w {
  @media (min-width: 1201px) and (max-width: 1440px) {
    @content;
  }
}
@mixin FourK {
  @media (min-width: 1921px) {
    @content;
  }
}
