@import '_variables';
@import '_mixins';

.everyday-section {
  width: 100%;
  height: 820px;
  margin-top: 3vw;
  display: flex;

  @include mobile {
    height: 100%;
    flex-direction: column;
    #item-0,
    #item-1 {
      @include mobile-l {
        input {
          width: 110px;
        }
        input:focus ~ .border:before {
          width: 130px;
        }
        .border {
          width: 130px;
        }
      }
    }

    #item-1,
    #item-2 {
      .question {
        overflow-y: auto;
        .clothes-container {
          margin-top: 10px;
          .clothes-card {
            margin: 5px;
            width: 25vw;
            .check-box {
              width: 25vw;
              height: 25vw;
              .angle-icon {
                right: 1px;
                top: 1px;
                width: 10px;
                height: 10px;
                svg {
                  position: absolute;
                  width: 10px;
                  height: 10px;
                }
              }
            }
            .clothes-text {
              width: 100%;
            }
          }
        }
        .next-button,
        .next-button-disabled {
          margin-top: 0;
        }
      }
    }
    .final {
      #item-1,
      #item-2 {
        .clothes-container {
          height: 100%;
          align-self: flex-start;
        }
      }
    }
  }
  .final {
    #item-1,
    #item-2 {
      .clothes-container {
        height: 100%;
      }
    }
  }
  #item-2 {
    .clothes-container {
      height: 100%;
      overflow-y: auto;
    }
  }

  .clothes-container {
    margin-top: 50px;
    transition: all 0.4s;
    height: 100%;
    width: 100%;
    .items {
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      width: 75%;
      height: 510px;
      @include mobile {
        width: 100%;
        height: 310px;
      }
      justify-content: center;
    }
    .clothes-left {
      justify-content: center;
    }
  }
  @include tablet {
    min-height: 800px;
    height: 100px;
  }
  @include mobile-l {
    .question {
      width: 100%;
      .question-cont {
        width: 100%;
      }
    }
    .question-line {
      width: 100%;
      flex-wrap: wrap;
    }
  }

  .body-content {
    position: relative;
    display: flex;
    width: 100%;
    .question-content {
      height: 100%;
    }
    @include desktop-w {
      min-height: 40vw;
    }
    @include mobile {
      .slide {
        align-items: flex-start;
        padding-top: 50px;
      }
      min-height: 900px;
      height: 70vh;
      width: 100%;
    }

    .question {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .next-button,
      .next-button-disabled {
        margin-top: 70px;
      }

      .select {
        @include mobile {
          width: 80vw;
        }
        @include mobile-l {
          width: 100%;
        }
      }
      input {
        margin: 0 5px;
        width: 200px;
      }
      input:focus ~ .border:before {
        width: 220px;
      }
      .border {
        width: 220px;
      }
      .question-cont {
        display: flex;
        align-items: center;
        padding-bottom: 1vw;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
      @include mobile {
        .question-line {
          flex-direction: column;
          align-items: center;
          .fix-wrap {
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
        justify-content: center;
        align-items: center;
        .question-cont {
          align-items: center;
          width: 100%;
        }
      }
      @include mobile-l {
        justify-content: flex-start;
        .question-cont {
          padding-bottom: 4vw;
          padding-top: 2vw;
        }
      }

      .question-line {
        display: flex;
        margin-top: 16px;
        span:last-child {
          align-self: flex-end;
          @include mobile {
            align-self: center;
          }
        }
      }

      @include mobile {
        .question-line {
          display: flex;
          flex-direction: column;
          margin-top: 0;
          align-items: center;
          .fix-wrap {
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
        justify-content: center;
        align-items: center;
        .question-cont {
          align-items: center;
          width: 100%;
        }
      }
    }
  }
  .final {
    .item {
      padding-bottom: 0;
    }
    @include mobile-l {
      .question-line {
        width: 100%;
        flex-wrap: wrap;
      }
      .clothes-left {
        padding-top: 0;
      }
    }
    input {
      color: $COLOR_TEXT_LIGHT;
    }
    .select {
      display: none;
    }
    .focused-select {
      display: block;
    }
    .next-button {
      margin-top: 51px;
    }
    @include mobile {
      min-height: auto;
      .next-button {
        margin-top: 30px;
      }
      .item {
        padding-bottom: 0;
        .item-fix {
          // border: 1px solid red;
          width: 90% !important;
          margin-left: auto;
          margin-right: auto;
        }
      }
      #item-2 {
        .item-fix {
          // border: 1px solid red;
          width: 100% !important;
          margin-left: auto;
          margin-right: auto;
          .question-cont {
            justify-content: center;
            align-items: center;
            .question-line {
              width: 90%;
            }
          }
        }
      }
    }
    .clothes-container {
      max-height: none;
      overflow-y: hidden;
    }
    .question {
      @include mobile {
        .question-line {
          margin-top: 16px;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          .fix-wrap {
            flex-direction: row;
            justify-content: center;
            align-items: center;
          }
        }
        justify-content: flex-start;
        align-items: flex-start;
        .question-cont {
          padding-top: 0;
          padding-bottom: 0;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      }
    }
  }
}
