@import '_variables';
@import '_mixins';

.check-box {
  width: 13vw;
  position: relative;
  // min-height: 13vw;
  height: 13vw; // ie 11
  border: 1px solid $COLOR_CHECK_BOX_BORDER;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @include desktop {
    width: 15vw;
    height: 15vw;
  }
  @include tablet {
    width: 19vw;
    height: 19vw;
  }
  @include mobile {
    width: 40vw;
    height: 40vw;
  }
  @include mobile-l {
    // width: 120px;
    // height: 120px;
    margin: 0;
    .check-box-icon {
      svg {
        transform: scale(0.7);
      }
    }
  }
  .angle-icon {
    position: absolute;
    right: 16px;
    top: 16px;
    @include mobile-l {
      right: 8px;
      top: 8px;
    }
    border: 1px solid $COLOR_CHECK_BOX_BORDER;
    width: 24px;
    height: 24px;
    border-radius: 24px;
  }
}
.checked {
  background-color: $COLOR_CHECEKED_BOX;
  border: 1px solid transparent !important;
  polygon {
    fill: $COLOR_CHECEKED_BOX !important;
  }
}
