$COLOR_LAYOUT_BACKGROUND: #f8f8f9;
$COLOR_PINK: #b6a0b4;
$COLOR_WHITE: #ffffff;
$COLOR_TEXT_LIGHT: #737079;
$COLOR_TEXT_BLACK: #18161e;
$COLOR_CHECK_BOX_BORDER: rgba(172, 171, 174, 0.4);
$COLOR_CHECEKED_BOX: #ede7ec;
$COLOR_SOFT_PINK: #efe3d7;
$COLOR_BODY_BACKGROUND: #e8e1e7;
$COLOR_INLINE_PINK: #b6a0b4;
$COLOR_GREY_BORDER: rgba(172, 171, 174, 0.4);;
$COLOR_BLACK: #2b2b2b;
$RED: #eb5757;
