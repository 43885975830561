@import '_variables';
@import '_mixins';

.brands-card {
  width: 250px;
  height: 140px;
  margin: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
  border: 1px solid rgba(172, 171, 174, 0.4);
  box-sizing: border-box;
  border-radius: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  svg {
    position: absolute;
    top: 13px;
    right: 15px;
    cursor: pointer;
    outline: none;
  }
  img {
    max-height: 100%;
    max-width: 100%;
    display: block;
  }
  .no-image {
    position: relative;
    height: 44px;
    width: 44px;
    margin: auto;
    top: 0;
    right: 0;
    fill: rgba(172, 171, 174, 0.4);
  }
}
