@import '_variables';
@import '_mixins';

.body-section {
  width: 100%;
  margin-top: 3vw;
  display: flex;
  @include mobile-l {
    flex-direction: column;
  }
  @include mobile {
    flex-direction: column;
  }
  .body-character {
    overflow: hidden;
    width: 75%;
    max-width: 475px;
    .body-back {
      height: 600px;
      background-color: $COLOR_BODY_BACKGROUND;
      overflow: hidden;
    }
    svg {
      transform: scale(1.4);
      margin-top: 40px;
    }
    @include FourK {
      .body-back {
        height: 700px;
      }
      svg {
        transform: scale(1.2);
      }
    }
    @include tablet {
      .body-back {
        height: auto;
      }
      svg {
        margin-top: 20px;
      }
    }
    @include mobile {
      width: 100%;
      max-width: none;
      background-color: #e8e1e7;
      .body-back {
        width: 50%;
        margin: auto;
        height: auto;
        svg {
          margin-top: 20px;
        }
      }
    }
    @include mobile-l {
      // width: 50%;
      .body-back {
        height: auto;
      }
      svg {
        transform: scale(1.2);
      }
    }
  }
  .body-content {
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    padding-top: 40px;
    @include desktop {
      min-height: 40vw;
    }
    @include tablet {
      min-height: 60vw;
      padding-top: 10px;
    }
    @include mobile {
      // height: 100vh;
      height: 480px;
      min-height: auto;
      // border: 1px solid red;
      padding-top: 10px;
      width: 100%;
    }
    @include mobile-l {
      padding-top: 10px;
      width: 100%;
    }
    .question {
      display: flex;
      justify-content: center;
      height: 100%;
      flex-direction: column;
      padding-left: 3.3vw;
      @include FourK {
        padding-left: 64px;
      }
      padding-right: 1.5vw;
      input {
        margin: 0 5px;
      }
      @include mobile {
        padding-left: 10px;
        padding-right: 10px;
        input {
          margin-left: auto;
          margin-right: auto;
        }
      }
      .question-cont {
        height: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
      }
      @include mobile {
        .question-line {
          flex-direction: column;
          align-items: center;
          .fix-wrap {
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
        justify-content: center;
        align-items: center;
        .question-cont {
          align-items: center;
          width: 100%;
        }
      }
    }
    .item {
      padding-bottom: 30px;
    }
    .question {
      .question-line:nth-child(2n) {
        margin-top: 30px;
      }
      .question-line {
        display: flex;
        // margin-bottom: 30px;
        flex-wrap: nowrap;
        .back-text {
          white-space: nowrap;
        }
        @include tablet {
          margin-bottom: 15px;
        }
        @media (max-width: 974px) {
          flex-wrap: wrap;
        }
        @include mobile {
          margin-bottom: 10px;
        }
        @include mobile-l {
          flex-wrap: wrap;
          margin-bottom: 10px;
        }
      }
    }
  }
  .final {
    .item {
      padding-bottom: 0;
    }
    @include mobile {
      min-height: auto;
      .item {
        padding-bottom: 0;
        .item-fix {
          // border: 1px solid red;
          // width: 80% !important;
          @include mobile-l {
            // width: 95% !important;
          }
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    input {
      color: $COLOR_TEXT_LIGHT;
    }
    .next-button {
      align-self: center;
    }
    .focused {
      color: $COLOR_TEXT_LIGHT;
    }
    .question-cont {
      .question-line:nth-child(2n) {
        margin-top: 0;
      }
      .question-line {
        margin-bottom: 20px;
      }
    }
    .question {
      @include mobile {
        .question-line {
          flex-direction: row;
          align-items: center;
          justify-content: center;
          .fix-wrap {
            flex-direction: row;
            justify-content: center;
            align-items: center;
          }
        }
        justify-content: flex-start;
        align-items: flex-start;
        .question-cont {
          align-items: center;
          width: 100%;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
    #item-0 {
      margin-top: 30px;
    }
  }
}
