@import '_variables';

.select {
  width: 100%;
}
.option {
  border: 1px solid $COLOR_GREY_BORDER;
  box-sizing: border-box;
  border-radius: 1px;
  margin-bottom: 10px;
  padding: 0 18px;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .option-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: $COLOR_TEXT_BLACK;
  }
  .option-icon {
    border: 1px solid $COLOR_CHECK_BOX_BORDER;
    width: 24px;
    height: 24px;
    border-radius: 24px;
  }
}
.option:hover {
  box-shadow: inset 0 0 0 2em $COLOR_LAYOUT_BACKGROUND;
}
