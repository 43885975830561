@import 'mixins';
.thank-for-describing {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .black-title {
    margin-bottom: 0 !important;
    width: 80%;
  }
  .pink-title {
    margin-bottom: 54px;
  }
  .grey-text {
    margin-bottom: 72px;
  }
  padding: 5vw 6vw 5.8vw 6vw;
  @include mobile-l {
    padding: 4vw 3vw;
    .black-title {
      width: 100%;
    }
    .pink-title {
      margin-bottom: 50px;
    }
  }
  @include mobile {
    padding: 10vw 14vw;
  }
  @include tablet {
    padding: 8vw 10vw;
  }
  @include desktop {
    padding: 6vw 8vw;
  }
  @include mobile {
    padding: 20px 5px;
    margin-top: 15px;
    .black-title:first-child {
      margin-bottom: 15px;
    }
    .black-title {
      width: 100%;
      font-size: 32px !important;
    }
    .pink-title {
      margin-bottom: 30px;
      font-size: 32px !important;
    }
  }
}
