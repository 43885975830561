@import 'mixins';
.brands-sizes {
  margin-top: 2.6vw;
  @include FourK {
    margin-top: 60px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .next-button {
    margin-top: 5vw;
  }
  .brands-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    .card {
      margin: 0 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .row {
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;
    }
  }
  .mini-title {
    margin: 1.2vw 0;
    @include FourK {
      margin: 23px 0;
    }
  }
  padding: 2vw 6vw 5.8vw 6vw;
  @include desktop-w {
    padding: 2vw 4vw;
    .brands-container {
      flex-wrap: wrap;
    }
  }
  @include desktop {
    padding: 6vw 8vw;
    .brands-container {
      flex-wrap: wrap;
    }
  }
  @include tablet {
    padding: 2vw 4vw;
    .brands-container {
      flex-direction: row;
      .row {
        flex-direction: column;
      }
    }
  }
  @include mobile {
    padding: 10vw 14vw;
    .brands-container {
      flex-direction: row;
      .row {
        flex-direction: column;
      }
      .card {
        .brands-card {
          // border: 1px solid red;
          margin: 0;
          margin-top: 20px;
        }
        .select-main {
          margin-top: 14px;
        }
      }
    }
  }
  @include mobile-l {
    padding: 4vw 0;
    .brands-container {
      flex-direction: column;
      .row {
        flex-direction: column;
      }
    }
  }
}
